<template>
  <div
    class="floating-pill-nav uk-background-inverse"
    uk-sticky="top: 20; offset: 70">
    <div class="floating-pill-nav-inner uk-container-large uk-margin-auto">
      <ul
        class="uk-flex wide uk-flex-center uk-subnav uk-subnav-pill"
        uk-scrollspy-nav="closest: li; scroll: true; offset: 200">
        <li v-for="item of list" :key="item.target" class="">
          <a :href="item.target" uk-scroll class="">{{ item.label }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: [],
    },
  },
  data: function () {
    return {};
  },
};
</script>

<style scoped lang="scss">
.floating-pill-nav {
  .uk-subnav {
    margin-bottom: 0;
  }
}

.uk-subnav.wide li a {
  @media screen and (max-width: 767px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>
